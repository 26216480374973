<template>
  <div name="app-lottie-animation" class="app-lottie-animation-cont">
    <base-lottie-animation
      :options="defaultOptions"
      :height="height"
      :width="width"
      class="lottie"
      @animCreated="handleAnimation" />
  </div>
</template>

<script>
import BaseLottieAnimation from '@/components/BaseLottieAnimation';
import * as animationData from '@/assets/lottie/celebration.json';

export default {
  name: 'AppLottieAnimation',
  components: {
    BaseLottieAnimation
  },
  props: {
    height: {
      type: Number,
      default: 600
    },
    width: {
      type: Number,
      default: 400
    },
    playAnimation: {
      type: Boolean,
      default: false
    },
    playDelay: {
      type: Number,
      default: 0
    },
    animationData: {
      type: Object,
      default: () => animationData.default
    }
  },
  data() {
    return {
      defaultOptions: {
        animationData: this.animationData,
        loop: false,
        autoplay: false
      },
      replayTimeout: undefined
    };
  },
  watch: {
    playAnimation(play) {
      play && this.play();
    }
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.addEventListener('complete', this.handleOnComplete);
      this.play();
    },
    play() {
      setTimeout(() => this.anim.goToAndPlay(0), this.playDelay);
    },
    handleOnComplete() {
      clearTimeout(this.replayTimeout);
      this.replayTimeout = setTimeout(() => {
        this.anim.goToAndPlay(0);
      }, 2500);
    }
  }
};
</script>
